var shuffleSeed = require("shuffle-seed");

function map(value, in_min, in_max, out_min, out_max, clamp = true) {
  let val =
    ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  if (clamp) {
    return this.clamp(val, 0, 1);
  }
  return val;
}

function clamp(input, min, max) {
  return input < min ? min : input > max ? max : input;
}

const removeElement = (array, itemId) => {
  const index = array.findIndex((element) => element.dbid === itemId);
  if (index >= 0) array.splice(index, 1);
  else array;
  return array;
};

const shuffleArray = (array) => {
  return shuffleSeed.shuffle(array, Date.now());
};

export { map, clamp, removeElement, shuffleArray };
